<template>
  <v-row class="app-invoice-preview">
    <v-col
      cols="12"
      md="9"
    >
      <v-card class="expenses-bill">
        <!-- Header -->
        <v-card-text class="pa-8">
          <div class="bill-header d-flex flex-wrap justify-space-between">
            <!-- Left Content -->
            <div class="text-left">
              <div class="d-flex align-center mb-3">
                <p class="font-weight-semibold payment-details-header mb-0 mr-4 mr-sm-2 text-no-wrap">
                  Vendor:
                </p>
                <v-autocomplete
                  ref="vendorList"
                  v-model="bill.vendor_id"
                  :items="vendors"
                  cache-items
                  item-text="name"
                  item-value="id"
                  single-line
                  outlined
                  dense
                  hide-details
                  return-object
                  placeholder="Select Vendor"
                  class="ml-3 mr-0 mr-sm-3"
                  @change="bill.vendor = $event; bill.vendor_id = $event.id"
                >
                  <template #append-item>
                    <div class="pa-0 mt-2 text-center append-select">
                      <v-btn
                        block
                        depressed
                        color="primary"
                        class="rounded-0"
                        @click="isAddNewVendorDialogOpen = true"
                      >
                        Add new vendor
                      </v-btn>
                    </div>
                  </template>
                </v-autocomplete>
              </div>
              <template v-if="bill.vendor">
                <p class="mb-1">
                  {{ bill.vendor.name }}
                </p>
                <p class="mb-1">
                  {{ bill.vendor.company }}
                </p>
                <p
                  v-if="bill.vendor.address"
                  class="mb-1"
                >
                  {{ bill.vendor.address }}
                </p>
                <p class="mb-1">
                  {{ bill.vendor.contact_number }}
                </p>
                <p class="mb-0">
                  {{ bill.vendor.email }}
                </p>
              </template>
            </div>
            <!-- Right Content -->
            <div class="text-right mt-5 mt-sm-0">
              <div class="mb-4 d-flex align-center">
                <span class="me-2">Date Issued: </span>
                <v-menu
                  v-model="isIssueDateMenuOpen"
                  :close-on-content-click="false"
                  :nudge-left="$vuetify.breakpoint.smAndDown ? 95 : 0"
                  transition="scale-transition"
                  offset-y
                  eager
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="billDateFormatted"
                      class="header-inputs flex-grow-0"
                      readonly
                      outlined
                      dense
                      hide-details="auto"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="bill.date_issued"
                    color="primary"
                    :first-day-of-week="1"
                    @input="isIssueDateMenuOpen = false"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="mb-0 mt-sm-2 d-flex align-center justify-end">
                <span class="me-2">Due Date: </span>
                <v-menu
                  v-model="isDueDateMenuOpen"
                  :close-on-content-click="false"
                  :nudge-left="$vuetify.breakpoint.smAndDown ? 95 : 0"
                  transition="scale-transition"
                  offset-y
                  eager
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dueDateFormatted"
                      class="header-inputs flex-grow-0"
                      readonly
                      outlined
                      dense
                      hide-details="auto"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="bill.due_date"
                    color="primary"
                    :first-day-of-week="1"
                    @input="isDueDateMenuOpen = false"
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <!-- Add purchased products -->
        <div
          v-if="!bill.quotation_id"
          class="add-products-form pa-8"
        >
          <div class="add-products-header d-none d-md-flex">
            <div class="px-5 flex-grow-1 font-weight-semibold">
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <span>Item</span>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <span>Cost Price</span>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <span>Quantity</span>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <span>Discount</span>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <span>Amount</span>
                </v-col>
              </v-row>
            </div>
            <div class="header-spacer"></div>
          </div>
          <span class="px-5 flex-grow-1 font-weight-semibold d-block d-sm-none">Item</span>
          <div
            v-for="(line_item, index) in bill.line_items_attributes"
            :key="index"
            class="my-3"
          >
            <v-card
              outlined
              class="d-flex"
            >
              <!-- Left Form -->
              <div class="pa-5 flex-grow-1">
                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-autocomplete
                      v-model="line_item.category_id"
                      :items="categories"
                      cache-items
                      item-text="name"
                      item-value="id"
                      single-line
                      outlined
                      dense
                      hide-details
                      placeholder="Select Category"
                      @change="fetchItems(line_item.category_id, index)"
                    >
                      <template #append-item>
                        <div class="pa-0 mt-2 text-center append-select">
                          <v-btn
                            block
                            depressed
                            color="primary"
                            class="rounded-0"
                            @click="isAddNewCategoryDialogOpen = true; selectedLineItemIndex = index; fetchCategoryGroups()"
                          >
                            Add new category
                          </v-btn>
                        </div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row v-if="line_item.category_id">
                  <v-col
                    cols="12"
                    md="4"
                    class="pt-0"
                  >
                    <v-autocomplete
                      v-if="itemOptionsLoaded"
                      v-model="line_item.item"
                      :items="itemOptions[index]"
                      item-text="name"
                      item-value="id"
                      single-line
                      outlined
                      dense
                      hide-details
                      return-object
                      placeholder="Select Item"
                      @change="line_item.price = line_item.item.cost_price; line_item.item_id = line_item.item.id; line_item.item_name = line_item.item.name"
                    >
                      <template #append-item>
                        <div class="pa-0 mt-2 text-center append-select">
                          <v-btn
                            block
                            depressed
                            color="primary"
                            class="rounded-0"
                            @click="isAddNewItemDialogOpen = true; selectedLineItemIndex = index; localItem.purchases_category_id = line_item.category_id"
                          >
                            Add new item
                          </v-btn>
                        </div>
                      </template>
                    </v-autocomplete>
                    <v-text-field
                      v-else
                      :value="line_item.item_name"
                      :append-icon="icons.mdiMenuDown"
                      single-line
                      dense
                      outlined
                      hide-details
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                    sm="4"
                    class="pt-0"
                  >
                    <span class="d-block d-sm-none">Cost Price</span>
                    <v-text-field
                      v-model.number="line_item.price"
                      outlined
                      dense
                      min="0"
                      type="number"
                      hide-details="auto"
                      placeholder="$"
                      @input="val => { line_item.price = Math.abs(parseFloat(val)) || 0 }"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                    sm="4"
                    class="pt-0"
                  >
                    <span class="d-block d-sm-none">Quantity</span>
                    <v-text-field
                      v-model.number="line_item.quantity"
                      outlined
                      dense
                      min="0"
                      type="number"
                      hide-details="auto"
                      placeholder="Quantity"
                      @input="val => { line_item.quantity = Math.abs(val) || 0 }"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                    sm="4"
                    class="pt-0"
                  >
                    <span class="d-block d-sm-none">Discount</span>
                    <v-text-field
                      v-model.number="line_item.discount"
                      outlined
                      dense
                      min="0"
                      type="number"
                      hide-details="auto"
                      placeholder="$"
                      @input="val => { line_item.discount = Math.abs(parseFloat(val)) || 0 }"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                    sm="4"
                    class="pt-0"
                  >
                    <p class="my-2">
                      <span class="d-inline d-md-none">Amount: </span>
                      <span>${{ parseFloat((line_item.price * line_item.quantity) - line_item.discount).toFixed(2) }}</span>
                    </p>
                  </v-col>
                </v-row>
              </div>
              <!-- Item Actions -->
              <div class="d-flex flex-column item-actions rounded-0 pa-1">
                <v-btn
                  icon
                  small
                  @click="removeLineItem(index)"
                >
                  <v-icon size="20">
                    {{ icons.mdiClose }}
                  </v-icon>
                </v-btn>
              </div>
            </v-card>
          </div>
          <v-btn
            color="primary"
            class="mt-4"
            outlined
            @click="addLineItem"
          >
            Add Item
          </v-btn>
        </div>

        <!-- Table -->
        <v-simple-table
          v-else
          class="purchased-items-table"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th>
                  ITEM
                </th>
                <th>
                  UNIT PRICE
                </th>
                <th>
                  QUANTITY
                </th>
                <th>
                  DISCOUNT
                </th>
                <th>
                  AMOUNT
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="line_item in bill.line_items_attributes"
                :key="line_item.id"
              >
                <td
                  v-if="line_item.item"
                  class="text-no-wrap py-2"
                >
                  {{ line_item.item.name }}<br>
                  <small class="text-wrap">{{ line_item.item.description }}</small>
                </td>
                <td class="text-no-wrap">
                  {{ line_item.price.toFixed(2) }}
                </td>
                <td>
                  {{ line_item.quantity }}
                </td>
                <td>
                  {{ line_item.discount.toFixed(2) }}
                </td>
                <td>
                  {{ line_item.amount.toFixed(2) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <!-- Save Vendor Dialog -->
        <v-dialog
          v-model="isAddNewVendorDialogOpen"
          width="500"
          persistent
        >
          <v-card>
            <v-card-title class="d-flex align-center mv-4 mb-4">
              New Vendor
              <v-spacer></v-spacer>
              <v-btn
                icon
                small
                @click="isAddNewVendorDialogOpen = false; saveVendorForm.reset()"
              >
                <v-icon size="22">
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text>
              <v-form
                ref="saveVendorForm"
                v-model="saveVendorValid"
              >
                <v-text-field
                  v-model="newVendor.name"
                  outlined
                  dense
                  label="Name"
                  :rules="[validators.required]"
                  hide-details="auto"
                  class="mb-6"
                ></v-text-field>
                <v-text-field
                  v-model="newVendor.company"
                  outlined
                  dense
                  label="Company"
                  :rules="[validators.required]"
                  hide-details="auto"
                  class="mb-6"
                ></v-text-field>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="newVendor.email"
                      outlined
                      dense
                      label="Email"
                      :rules="[validators.required]"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="newVendor.contact_number"
                      outlined
                      dense
                      label="Number"
                      :rules="[validators.required]"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-textarea
                  v-model="newVendor.note"
                  outlined
                  rows="3"
                  label="Notes"
                ></v-textarea>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="primary"
                block
                :loading="saveVendorLoading"
                :disabled="saveVendorLoading || !saveVendorValid"
                @click="saveVendor"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Add Item Dialog -->
        <v-dialog
          v-model="isAddNewItemDialogOpen"
          width="500"
          persistent
        >
          <v-card>
            <v-card-title class="d-flex align-center mv-4 mb-4">
              <v-row>
                <span class="ml-3 mt-5">
                  New Item For This Category
                </span>
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  small
                  class="mr-2 mt-4"
                  @click="isAddNewItemDialogOpen = false; saveItemForm.reset()"
                >
                  <v-icon size="22">
                    {{ icons.mdiClose }}
                  </v-icon>
                </v-btn>
              </v-row>
            </v-card-title>

            <v-card-text>
              <v-form
                ref="saveItemForm"
                v-model="saveItemValid"
              >
                <v-text-field
                  v-model="localItem.name"
                  outlined
                  dense
                  label="Name"
                  :rules="[validators.required]"
                  hide-details="auto"
                  class="mb-6"
                ></v-text-field>
                <v-textarea
                  v-model="localItem.description"
                  outlined
                  rows="3"
                  label="Description"
                  hide-details="auto"
                  class="mb-6"
                ></v-textarea>
                <v-text-field
                  v-model="localItem.cost_price"
                  outlined
                  dense
                  min="0"
                  type="number"
                  placeholder="Cost Price"
                  prefix="BND$"
                  hide-details="auto"
                  class="mb-6"
                ></v-text-field>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="primary"
                block
                :loading="saveItemLoading"
                :disabled="saveItemLoading || !saveItemValid"
                @click="saveItem"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Add Category Dialog -->
        <v-dialog
          v-model="isAddNewCategoryDialogOpen"
          width="500"
          persistent
        >
          <v-card>
            <v-card-title class="d-flex align-center mv-4 mb-4">
              New Category
              <v-spacer></v-spacer>
              <v-btn
                icon
                small
                @click="isAddNewCategoryDialogOpen = false; saveCategoryForm.reset()"
              >
                <v-icon size="22">
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="saveCategoryForm"
                v-model="saveCategoryValid"
              >
                <v-text-field
                  v-model="category.name"
                  outlined
                  dense
                  label="Name"
                  :rules="[validators.required]"
                  hide-details="auto"
                  class="mb-6"
                ></v-text-field>
                <v-autocomplete
                  v-model="category.category_group_id"
                  label="Category Group"
                  :items="categoryGroups"
                  item-text="name"
                  item-value="id"
                  single-line
                  outlined
                  dense
                  hide-details
                  return-object
                  class="mb-6"
                  @change="category.category_group_id = $event.id"
                ></v-autocomplete>
                <v-textarea
                  v-model="category.description"
                  outlined
                  rows="3"
                  label="Description"
                  hide-details="auto"
                  class="mb-6"
                ></v-textarea>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="primary"
                block
                :loading="saveCategoryLoading"
                :disabled="saveCategoryLoading || !saveCategoryValid"
                @click="saveCategory"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-divider></v-divider>

        <!-- Total -->
        <v-card-text class="pa-8">
          <div class="d-flex justify-space-between flex-wrap flex-column flex-sm-row">
            <div>
              <div class="d-flex align-center mb-4">
                <p class="font-weight-semibold payment-details-header mb-0 mr-4 mr-sm-2 text-no-wrap">
                  Receipt:
                </p>
                <a
                  v-if="bill.receipt_url"
                  :href="bill.receipt_url"
                  target="_blank"
                >
                  {{ utilsService.truncate(bill.receipt_name, 30) }}
                  <v-icon
                    size="18"
                    color="primary"
                  >{{ icons.mdiOpenInNew }}</v-icon>
                </a>
              </div>
              <v-file-input
                prepend-icon=""
                :append-icon="icons.mdiPaperclip"
                accept="application/pdf, image/jpeg, image/png"
                show-size
                outlined
                :rules="receiptUploadRules"
                :label="bill.receipt_url ? 'Upload New Receipt' : 'Upload Receipt'"
                hide-details="auto"
                class="mb-5 upload-receipt-field"
                @change="directUploadReceipt($event)"
              >
                <template v-slot:selection="{ text }">
                  <v-chip
                    small
                    label
                    color="primary"
                  >
                    {{ text }}
                  </v-chip>
                </template>

                <template v-slot:append>
                  <v-progress-circular
                    v-if="uploadLoading"
                    color="primary"
                    indeterminate
                    size="25"
                  ></v-progress-circular>
                </template>
              </v-file-input>
            </div>
            <v-spacer></v-spacer>
            <div>
              <table class="w-full">
                <tr>
                  <td class="pe-16">
                    Subtotal:
                  </td>
                  <th class="text-right">
                    ${{ parseFloat(bill.line_items_attributes.reduce((sum, item) => sum + (item.price * item.quantity), 0)).toFixed(2) }}
                  </th>
                </tr>
                <tr>
                  <td class="pe-16">
                    Total Discount:
                  </td>
                  <th class="text-right">
                    ${{ parseFloat(bill.line_items_attributes.reduce((sum, item) => sum + item.discount, 0)).toFixed(2) }}
                  </th>
                </tr>
              </table>
              <v-divider class="my-3"></v-divider>
              <table class="w-full">
                <tr>
                  <td class="pe-16">
                    Total:
                  </td>
                  <th class="text-right">
                    ${{ parseFloat(bill.line_items_attributes.reduce((sum, item) => sum + (item.price * item.quantity), 0) - bill.line_items_attributes.reduce((sum, item) => sum + item.discount, 0)).toFixed(2) }}
                  </th>
                </tr>
                <tr v-if="bill.amount_due">
                  <td class="pe-16">
                    Amount Due:
                  </td>
                  <th class="text-right">
                    ${{ bill.amount_due.toFixed(2) }}
                  </th>
                </tr>
              </table>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="px-8 py-6">
          <div class="mb-0">
            <p class="font-weight-semibold mb-3">
              Note:
            </p>
            <vue-editor
              v-model="bill.note"
              placeholder="For internal use only"
              :editor-toolbar="customToolBar"
              :class="{ 'dark-mode-toolbar': $vuetify.theme.dark }"
            />
          </div>
        </v-card-text>
      </v-card>

      <v-card
        v-if="billId && payments"
        class="mt-5"
      >
        <v-card-title class="d-flex align-center">
          <v-row>
            <v-col class="my-auto text-no-wrap text-subtitle-1 text-sm-h6">
              Payment History
            </v-col>

            <v-col align="end">
              <v-btn
                color="primary"
                class="me-3 invoice-button"
                @click="isAddNewPaymentDialogOpen = true"
              >
                <v-icon
                  size="18"
                  class="me-1"
                >
                  {{ icons.mdiPlus }}
                </v-icon>
                <span class="d-none d-sm-block">Add Payment</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table
          :headers="paymentsTableColumnHeaders"
          :items="payments"
          :options.sync="options"
          hide-default-footer
          fixed-header
          :items-per-page="50"
          mobile-breakpoint="0"
          :loading="fetchPaymentsLoading"
          class="text-no-wrap fixed-action d-none d-sm-block"
        >
          <!-- Actions -->
          <template #[`item.actions`]="{item}">
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="me-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="fetchPayment(item.id), isAddNewPaymentDialogOpen = true">
                  <v-list-item-title>
                    <v-icon
                      size="20"
                      class="me-2"
                    >
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                    <span>View/Edit</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item @click="payment = item; isDuplicateDialogVisible = true">
                  <v-list-item-title>
                    <v-icon
                      size="20"
                      class="me-2"
                    >
                      {{ icons.mdiContentCopy }}
                    </v-icon>
                    <span>Duplicate</span>
                  </v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="payment = item; isVoidDialogVisible = true">
                  <v-list-item-title>
                    <v-icon
                      size="20"
                      class="me-2"
                    >
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                    <span>Void</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>

        <!-- table view for mobile -->
        <v-data-table
          :headers="paymentsTableSmColumnHeaders"
          :items="payments"
          :options.sync="options"
          hide-default-footer
          fixed-header
          :items-per-page="50"
          :expanded.sync="expanded"
          single-expand
          show-expand
          mobile-breakpoint="0"
          :loading="fetchPaymentsLoading"
          class="text-no-wrap fixed-action d-block d-sm-none"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td
              class="table-expand"
              :colspan="headers.length"
            >
              <div class="d-flex">
                <p class="mt-3">
                  Payment Date: {{ item.payment_date }}
                </p>
                <v-spacer></v-spacer>
                <div class="fixed-expand-action">
                  <v-menu
                    bottom
                    left
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item @click="fetchPayment(item.id), isAddNewPaymentDialogOpen = true">
                        <v-list-item-title>
                          <v-icon
                            size="16"
                            class="me-2"
                          >
                            {{ icons.mdiPencilOutline }}
                          </v-icon>
                          <span class="caption">View/Edit</span>
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item @click="payment = item; isDuplicateDialogVisible = true">
                        <v-list-item-title>
                          <v-icon
                            size="16"
                            class="me-2"
                          >
                            {{ icons.mdiContentCopy }}
                          </v-icon>
                          <span class="caption">Duplicate</span>
                        </v-list-item-title>
                      </v-list-item>

                      <v-divider></v-divider>

                      <v-list-item @click="payment = item; isVoidDialogVisible = true">
                        <v-list-item-title>
                          <v-icon
                            size="16"
                            class="me-2"
                          >
                            {{ icons.mdiDeleteOutline }}
                          </v-icon>
                          <span class="caption">Void</span>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </div>
              <p
                class="text-wrap"
                v-html="item.note"
              ></p>
            </td>
          </template>
        </v-data-table>

        <!-- Save payment dialog -->
        <v-dialog
          v-model="isAddNewPaymentDialogOpen"
          width="500"
        >
          <v-card>
            <v-card-title class="d-flex align-center mv-4">
              {{ payment.id ? 'Edit' : 'Add' }} Payment
              <v-spacer></v-spacer>
              <v-btn
                icon
                small
                @click="isAddNewPaymentDialogOpen = false"
              >
                <v-icon size="22">
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text>
              <v-form v-model="savePaymentValid">
                <v-menu
                  v-model="isPaymentDateMenuOpen"
                  :close-on-content-click="false"
                  :nudge-left="$vuetify.breakpoint.smAndDown ? 95 : 0"
                  transition="scale-transition"
                  offset-y
                  eager
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="paymentDateFormatted"
                      :append-icon="icons.mdiCalendar"
                      readonly
                      dense
                      outlined
                      hide-details
                      class="header-inputs flex-grow-0 mb-6"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="payment.payment_date"
                    color="primary"
                    :first-day-of-week="1"
                    @input="isPaymentDateMenuOpen = false"
                  ></v-date-picker>
                </v-menu>
                <v-text-field
                  ref="paymentAmountInput"
                  v-model="payment.payment_amount"
                  outlined
                  dense
                  min="0"
                  type="number"
                  placeholder="Payment Amount"
                  prefix="BND$"
                  hide-details="auto"
                  oninput="if (this.value < 0) this.value = 0;"
                  class="mb-6"
                  @max-payment="payment.payment_amount = $event; $forceUpdate()"
                >
                  <template #append>
                    <v-chip
                      label
                      small
                      color="primary"
                      class="v-chip-light-bg font-weight-semibold primary--text hover-pointer"
                      @click="$refs.paymentAmountInput.$emit('max-payment', bill.amount_due)"
                    >
                      MAX
                    </v-chip>
                  </template>
                </v-text-field>
                <v-autocomplete
                  ref="paymentMethodList"
                  v-model="payment.payment_method_id"
                  :items="paymentMethods"
                  cache-items
                  item-text="name"
                  item-value="id"
                  single-line
                  outlined
                  dense
                  hide-details
                  return-object
                  placeholder="Select Method"
                  class="payment-inputs my-auto flex-grow-0 mb-6"
                  @change="payment.payment_method = $event; payment.payment_method_id = $event.id"
                >
                  <template #append-item>
                    <div class="pa-0 mt-2 text-center append-select">
                      <v-btn
                        block
                        depressed
                        color="primary"
                        class="rounded-0"
                        @click="addPaymentMethodDialog = true"
                      >
                        Add new payment method
                      </v-btn>
                    </div>
                  </template>
                </v-autocomplete>
                <v-textarea
                  v-model="payment.note"
                  outlined
                  rows="3"
                  label="Notes"
                ></v-textarea>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="primary"
                block
                :loading="savePaymentLoading"
                :disabled="savePaymentLoading || !savePaymentValid"
                @click="savePayment"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Duplicate dialog -->
        <v-dialog
          v-model="isDuplicateDialogVisible"
          width="500"
        >
          <v-card>
            <v-card-title class="d-flex align-center mv-4">
              Duplicate {{ payment.payment_amount }} payment?
              <v-spacer></v-spacer>
              <v-btn
                icon
                small
                @click="isDuplicateDialogVisible = false"
              >
                <v-icon size="22">
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text>
              This payment will be duplicated. It copy all data present in this current payment.
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="primary"
                block
                :loading="duplicateLoading"
                :disabled="duplicateLoading"
                class="mt-3"
                @click="duplicatePayment()"
              >
                Make another copy
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Void dialog -->
        <v-dialog
          v-model="isVoidDialogVisible"
          width="500"
        >
          <v-card>
            <v-card-title class="d-flex align-center mv-4">
              Void {{ payment.payment_amount }} payment?
              <v-spacer></v-spacer>
              <v-btn
                icon
                small
                @click="isVoidDialogVisible = false"
              >
                <v-icon size="22">
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text>
              This receipt will be voided. It won't be taken into any calculations.
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="error"
                block
                :loading="voidLoading"
                :disabled="voidLoading"
                class="mt-3"
                @click="voidPayment()"
              >
                Remove
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Add Payment Method Dialog -->
        <v-dialog
          v-model="addPaymentMethodDialog"
          width="500"
          persistent
        >
          <v-card>
            <v-card-title class="d-flex align-center mv-4 mb-4">
              New Payment Method
              <v-spacer></v-spacer>
              <v-btn
                icon
                small
                @click="addPaymentMethodDialog = false; savePaymentMethodForm.reset()"
              >
                <v-icon size="22">
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text>
              <v-form
                ref="savePaymentMethodForm"
                v-model="savePaymentMethodValid"
              >
                <v-text-field
                  v-model="newPaymentMethod.name"
                  outlined
                  dense
                  label="Name"
                  :rules="[validators.required]"
                  hide-details="auto"
                  class="mb-6"
                ></v-text-field>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="primary"
                block
                :loading="savePaymentMethodLoading"
                :disabled="savePaymentMethodLoading || !savePaymentMethodValid"
                @click="savePaymentMethod"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="4"
              lg="2"
              md="3"
            >
              <div class="d-flex align-center">
                <v-select
                  v-model="tablePagination.per"
                  :items="listLengthOptions"
                  single-line
                  outlined
                  dense
                  hide-details
                  class="invoice-list-row-selection"
                  @input="listLengthChange()"
                ></v-select>
                <div class="pl-3">
                  Rows
                </div>
              </div>
            </v-col>

            <v-spacer></v-spacer>

            <v-col
              lg="10"
              md="9"
              cols="4"
              class="d-flex justify-end"
            >
              <v-pagination
                v-model="tablePagination.current_page"
                total-visible="6"
                :length="tablePagination.total_page"
                @input="fetchPayments"
              ></v-pagination>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col
      cols="12"
      md="3"
    >
      <v-card class="mb-6">
        <v-card-text>
          <v-btn
            color="primary"
            block
            :loading="saveFormLoading"
            :disabled="saveFormLoading"
            @click="saveBill"
          >
            Save
          </v-btn>
        </v-card-text>
      </v-card>
      <v-alert
        v-for="(error, index) in saveFormErrors"
        :key="index"
        border="left"
        color="error"
        dark
        text
      >
        {{ error }}
      </v-alert>

      <ActivityLog :activities="activities" />
    </v-col>
  </v-row>
</template>

<script>
import {
  ref,
  inject,
  onMounted,
  computed,
} from '@vue/composition-api'
import { VueEditor } from 'vue2-editor'
import {
  mdiClose,
  mdiMenuDown,
  mdiPaperclip,
  mdiOpenInNew,
  mdiPlus,
  mdiCalendar,
  mdiDotsVertical,
  mdiPencilOutline,
  mdiContentCopy,
  mdiDeleteOutline,
} from '@mdi/js'
import { useRouter } from '@core/utils'
import { required } from '@core/utils/validation'
import ActivityLog from '@/components/activity/ActivityLog'

export default {
  components: { VueEditor, ActivityLog },
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const utilsService = inject('utilsService')
    const { route, router } = useRouter()
    const billId = route.value.params.id

    // Properties
    const [dayNow, monthNow, yearNow] = new Date().toLocaleDateString('en-SG').split('/')
    const bill = ref({
      date_issued: `${yearNow}-${monthNow}-${dayNow}`,
      due_date: `${yearNow}-${monthNow}-${dayNow}`,
      receipt: null,
      line_items_attributes: [{
        item_id: null,
        category_id: null,
        name: null,
        price: 0.00,
        quantity: 1,
        discount: 0.00,
        amount: 0.00,
      }],
    })
    const isIssueDateMenuOpen = ref(false)
    const isDueDateMenuOpen = ref(false)

    const vendors = ref([])
    const newVendor = ref({})
    const saveVendorForm = ref(null)
    const saveVendorValid = ref(false)
    const saveVendorLoading = ref(false)
    const isAddNewVendorDialogOpen = ref(false)

    const itemOptions = ref([])
    const localItem = ref({})
    const saveItemForm = ref(null)
    const saveItemValid = ref(false)
    const saveItemLoading = ref(false)
    const isAddNewItemDialogOpen = ref(false)
    const itemOptionsLoaded = ref(false)
    const selectedLineItemIndex = ref(null)
    const lineItemsToBeDeleted = ref([])

    const categories = ref([])
    const category = ref({})
    const categoryGroups = ref([])
    const saveCategoryForm = ref(null)
    const saveCategoryValid = ref(false)
    const saveCategoryLoading = ref(false)
    const isAddNewCategoryDialogOpen = ref(false)

    const payment = ref({
      expense_bill_id: billId,
      payment_date: `${yearNow}-${monthNow}-${dayNow}`,
    })
    const payments = ref([])
    const savePaymentValid = ref(false)
    const savePaymentLoading = ref(false)
    const isAddNewPaymentDialogOpen = ref(false)
    const isPaymentDateMenuOpen = ref(false)
    const fetchPaymentsLoading = ref(false)

    const paymentMethods = ref([])
    const newPaymentMethod = ref({})
    const savePaymentMethodForm = ref(null)
    const savePaymentMethodValid = ref(false)
    const savePaymentMethodLoading = ref(false)
    const addPaymentMethodDialog = ref(false)

    const isVoidDialogVisible = ref(false)
    const voidLoading = ref(false)
    const isDuplicateDialogVisible = ref(false)
    const duplicateLoading = ref(false)

    const activities = ref([])

    const uploadLoading = ref(false)
    const receiptUploadRules = [value => !value || value.size < 25000000 || 'You file has to be lower than 25MB']
    const saveFormLoading = ref(false)
    const saveFormErrors = ref([])

    const customToolBar = [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' },
      ],
    ]

    // Table Handlers
    const expanded = ref([])
    const options = ref({
      sortBy: ['payment_date'],
      sortDesc: [true],
    })
    const listLengthOptions = ref([5, 25, 50])
    const tablePagination = ref({
      current_page: 1,
      per: 5,
      total_count: 0,
      total_page: 0,
    })
    const paymentsTableColumnHeaders = [
      { text: 'PAYMENT DATE', value: 'payment_date' },
      { text: 'PAYMENT AMOUNT', value: 'payment_amount' },
      { text: 'PAYMENT METHOD', value: 'payment_method' },
      { text: 'NOTES', value: 'note', sortable: false },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]
    const paymentsTableSmColumnHeaders = [
      { text: 'PAYMENT DATE', value: 'payment_date' },
      { text: 'PAYMENT AMOUNT', value: 'payment_amount' },
      { text: '', value: 'data-table-expand' },
    ]

    // Computed
    const billDateFormatted = computed(() => utilsService.formatDate(bill.value.date_issued))
    const dueDateFormatted = computed(() => utilsService.formatDate(bill.value.due_date))
    const paymentDateFormatted = computed(() => utilsService.formatDate(payment.value.payment_date))

    // Methods
    const fetchVendors = () => {
      store
        .dispatch('expensesSettingsStore/fetchVendors', { no_pagination: true })
        .then(response => {
          vendors.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching vendors. Please refresh!')
        })
    }
    const fetchItems = (categoryId = null, lineItemIndex = null) => {
      itemOptionsLoaded.value = false
      store
        .dispatch('expensesSettingsStore/fetchItems', { category_id: categoryId, no_pagination: true })
        .then(response => {
          bill.value.line_items_attributes[lineItemIndex].itemOptions = response.data.data
          itemOptions.value.push(response.data.data)
          itemOptions.value[lineItemIndex] = response.data.data
          itemOptionsLoaded.value = true
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching items. Please refresh!')
        })
    }
    const fetchCategories = () => {
      store
        .dispatch('expensesSettingsStore/fetchCategories', { no_pagination: true })
        .then(response => {
          categories.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching categories. Please refresh!')
        })
    }
    const fetchCategoryGroups = () => {
      store
        .dispatch('knowledgeBaseStore/fetchCategoryGroups', { type: 'purchases' })
        .then(response => {
          categoryGroups.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching category groups. Please refresh!')
        })
    }
    const fetchPaymentMethods = () => {
      store
        .dispatch('knowledgeBaseStore/fetchPaymentMethods', { no_pagination: true })
        .then(response => {
          paymentMethods.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching payment methods. Please refresh!')
        })
    }
    const fetchPayments = () => {
      fetchPaymentsLoading.value = true
      store
        .dispatch('billStore/fetchPayments', {
          id: billId,
          page: tablePagination.value.current_page,
          per: tablePagination.value.per,
          sort_by: options.value.sortBy[0],
          sort_dir: options.value.sortDesc[0] ? 'desc' : 'asc',
        })
        .then(response => {
          const { data, pagination } = response.data
          payments.value = data
          tablePagination.value = pagination
          fetchPaymentsLoading.value = false
        })
        .catch(error => {
          fetchPaymentsLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching payments. Please refresh!')
        })
    }
    const fetchBill = id => {
      store
        .dispatch('billStore/fetchBill', { id, as_form: true })
        .then(response => {
          bill.value = response.data.data
          const lineItems = bill.value.line_items_attributes
          for (let i = 0; i < lineItems.length; i += 1) {
            fetchItems(lineItems[i].category_id, i)
          }
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching bill. Please refresh!')
        })
    }
    const fetchPayment = id => {
      store
        .dispatch('billStore/fetchPayment', { id })
        .then(response => {
          payment.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching payment. Please refresh!')
        })
    }
    const addLineItem = () => {
      bill.value.line_items_attributes.push({
        item_id: null,
        category_id: null,
        price: 0.00,
        quantity: 1,
        discount: 0.00,
        amount: 0.00,
      })
    }
    const removeLineItem = index => {
      const lineItems = bill.value.line_items_attributes
      if (lineItems[index].id) {
        lineItems[index]._destroy = true
        lineItemsToBeDeleted.value.push(lineItems[index])
      }
      lineItems.splice(index, 1)
    }
    const saveVendor = () => {
      saveVendorLoading.value = true
      store
        .dispatch('expensesSettingsStore/createVendor', newVendor.value)
        .then(response => {
          snackbarService.success(response.data.message)
          isAddNewVendorDialogOpen.value = false
          saveVendorLoading.value = false
          saveVendorForm.value.reset()
          fetchVendors()

          bill.value.vendor = response.data.data
          bill.value.vendor_id = response.data.data.id
        })
        .catch(error => {
          saveVendorLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while adding vendor. Please refresh!')
        })
    }
    const saveItem = () => {
      saveItemLoading.value = true
      store
        .dispatch('expensesSettingsStore/createItem', localItem.value)
        .then(response => {
          snackbarService.success(response.data.message)
          isAddNewItemDialogOpen.value = false
          saveItemLoading.value = false
          saveItemForm.value.reset()
          fetchItems(bill.value.line_items_attributes[selectedLineItemIndex.value].category_id, selectedLineItemIndex.value)

          bill.value.line_items_attributes[selectedLineItemIndex.value].item = response.data.data
          bill.value.line_items_attributes[selectedLineItemIndex.value].item_id = response.data.data.id
          bill.value.line_items_attributes[selectedLineItemIndex.value].price = response.data.data.cost_price
          selectedLineItemIndex.value = null
        })
        .catch(error => {
          saveItemLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while adding item. Please refresh!')
        })
    }
    const saveCategory = () => {
      saveCategoryLoading.value = true
      store
        .dispatch('expensesSettingsStore/createCategory', category.value)
        .then(response => {
          const { data, message } = response.data
          snackbarService.success(message)
          isAddNewCategoryDialogOpen.value = false
          saveCategoryLoading.value = false
          saveCategoryForm.value.reset()
          fetchCategories()

          fetchItems(data.id, selectedLineItemIndex.value)
          bill.value.line_items_attributes[selectedLineItemIndex.value].category_id = data.id
          selectedLineItemIndex.value = null
        })
        .catch(error => {
          saveCategoryLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while adding category. Please refresh!')
        })
    }
    const savePaymentMethod = () => {
      savePaymentMethodLoading.value = true
      store
        .dispatch('knowledgeBaseStore/createPaymentMethod', newPaymentMethod.value)
        .then(response => {
          snackbarService.success(response.data.message)
          addPaymentMethodDialog.value = false
          savePaymentMethodLoading.value = false
          savePaymentMethodForm.value.reset()
          fetchPaymentMethods()

          payment.value.payment_method = response.data.data.name
          payment.value.payment_method_id = response.data.data.id
        })
        .catch(error => {
          savePaymentMethodLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while adding payment method. Please refresh!')
        })
    }
    const saveBill = () => {
      saveFormLoading.value = true
      const billParams = { ...bill.value }
      billParams.line_items_attributes = billParams.line_items_attributes.concat(lineItemsToBeDeleted.value)
      store
        .dispatch(`billStore/${billId ? 'updateBill' : 'createBill'}`, billParams)
        .then(response => {
          snackbarService.success(response.data.message)
          saveFormLoading.value = false
          router.push('/expenses/bill')
        })
        .catch(error => {
          saveFormLoading.value = false
          saveFormErrors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while saving bill. Please refresh!')
        })
    }
    const savePayment = () => {
      savePaymentLoading.value = true
      store
        .dispatch(`billStore/${payment.value.id ? 'updatePayment' : 'createPayment'}`, payment.value)
        .then(response => {
          snackbarService.success(response.data.message)
          isAddNewPaymentDialogOpen.value = false
          savePaymentLoading.value = false
          fetchPayments()
          fetchBill(billId)

          payment.value = {
            expense_bill_id: billId,
            payment_date: `${yearNow}-${monthNow}-${dayNow}`,
          }
        })
        .catch(error => {
          savePaymentLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while saving payment. Please refresh!')
        })
    }
    const directUploadReceipt = file => {
      if (file == null) {
        bill.value.receipt = null
        bill.value.receipt_name = null

        return
      }

      uploadLoading.value = true
      store
        .dispatch('billStore/directUploadReceipt', file)
        .then(response => {
          uploadLoading.value = false
          if (response) {
            bill.value.receipt = response.signed_id
            bill.value.receipt_name = response.filename
          }
        })
        .catch(error => {
          uploadLoading.value = false
          snackbarService.error(error || 'Something went wrong while uploading receipt. Please refresh!')
        })
    }
    const duplicatePayment = () => {
      duplicateLoading.value = true
      store
        .dispatch('billStore/duplicatePayment', { id: payment.value.id })
        .then(response => {
          snackbarService.success(response.data.message)
          fetchPayments()
          fetchBill(billId)
          isDuplicateDialogVisible.value = false
          duplicateLoading.value = false
        })
        .catch(error => {
          duplicateLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while duplicating payment. Please refresh!')
        })
    }
    const voidPayment = () => {
      voidLoading.value = true
      store
        .dispatch('billStore/voidPayment', { id: payment.value.id })
        .then(response => {
          snackbarService.success(response.data.message)
          fetchPayments()
          fetchBill(billId)
          isVoidDialogVisible.value = false
          voidLoading.value = false
        })
        .catch(error => {
          voidLoading.value = false
          snackbarService.error(error.data.message || 'Something went wrong while voiding payment. Please refresh!')
        })
    }
    const fetchActivities = () => {
      store
        .dispatch('billStore/fetchActivities', { id: billId })
        .then(response => {
          activities.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching activities. Please refresh!')
        })
    }

    // Mounted
    onMounted(() => {
      fetchVendors()
      fetchPaymentMethods()
      fetchCategories()
      if (billId) {
        fetchBill(billId)
        fetchPayments()
        fetchActivities()
      }
    })

    return {
      // Initializers
      billId,
      utilsService,

      // Computed
      billDateFormatted,
      dueDateFormatted,
      paymentDateFormatted,

      // Properties
      bill,
      isIssueDateMenuOpen,
      isDueDateMenuOpen,

      vendors,
      newVendor,
      saveVendorForm,
      saveVendorValid,
      saveVendorLoading,
      isAddNewVendorDialogOpen,

      itemOptions,
      localItem,
      saveItemForm,
      saveItemValid,
      saveItemLoading,
      isAddNewItemDialogOpen,
      itemOptionsLoaded,
      selectedLineItemIndex,

      categories,
      category,
      categoryGroups,
      saveCategoryForm,
      saveCategoryValid,
      saveCategoryLoading,
      isAddNewCategoryDialogOpen,

      uploadLoading,
      customToolBar,

      saveFormLoading,
      saveFormErrors,

      payment,
      payments,
      savePaymentValid,
      savePaymentLoading,
      isAddNewPaymentDialogOpen,
      isPaymentDateMenuOpen,
      fetchPaymentsLoading,

      paymentMethods,
      newPaymentMethod,
      savePaymentMethodForm,
      savePaymentMethodValid,
      savePaymentMethodLoading,
      addPaymentMethodDialog,

      isVoidDialogVisible,
      voidLoading,
      isDuplicateDialogVisible,
      duplicateLoading,
      activities,

      // Table Handlers
      expanded,
      options,
      listLengthOptions,
      tablePagination,
      paymentsTableColumnHeaders,
      paymentsTableSmColumnHeaders,

      // Validators
      validators: {
        required,
      },
      receiptUploadRules,

      // Icons
      icons: {
        mdiClose,
        mdiMenuDown,
        mdiPaperclip,
        mdiOpenInNew,
        mdiPlus,
        mdiCalendar,
        mdiDotsVertical,
        mdiPencilOutline,
        mdiContentCopy,
        mdiDeleteOutline,
      },

      // Methods
      fetchItems,
      fetchCategoryGroups,
      fetchPayments,
      fetchPayment,
      addLineItem,
      removeLineItem,
      saveVendor,
      saveItem,
      saveCategory,
      savePaymentMethod,
      saveBill,
      savePayment,
      directUploadReceipt,
      duplicatePayment,
      voidPayment,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/bill.scss';

.expenses-bill {
  .header-inputs {
    width: 122px;
  }

  .upload-receipt-field {
    min-width: 275px;
  }

  .add-products-form {
    .header-spacer {
      // This is according to item actions width
      width: 39px;
    }
    .item-actions {
      @at-root {
        @include theme--child(add-products-form) using ($material) {
          .item-actions {
            border-left: thin solid map-deep-get($material, 'dividers');
          }
        }
      }
    }
  }
}

.append-select {
  position: sticky;
  bottom: 1px;
  display: flex;
  justify-content: center;
}
</style>
